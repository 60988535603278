<template>
	<div class="activity-code">
		<div class="activity-code-logo">
			<inline-svg :src="require('../assets/eaaci-activity.svg')" width="40"></inline-svg>
		</div>
		<h1>Activity Challenge</h1>
		<div class="activity-code-status">
			<inline-svg v-if="stateSuccess" :src="require('../assets/ok-circle.svg')" width="40"></inline-svg>
			<inline-svg v-else :src="require('../assets/cancel-circle.svg')" width="40"></inline-svg>
			<span v-if="stateSuccess && message">{{ message }}</span>
			<span v-if="(stateSuccess && !message) || !stateSuccess">{{ stateTranslated }}</span>
		</div>

		<h2>{{ activityTitle }}</h2>
		<h3>{{ categoryTitle }}</h3>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import meta from '../utils/meta.js';

	export default {
		name: 'ActivityCode',
		data() {
			return {
				state: null,
				categoryTitle: null,
				activityTitle: null,
				message: null,
			};
		},
		computed: {
			stateSuccess() {
				return this.state === 'SUCCESSFULLY_COMPLETED';
			},
			stateTranslated() {
				if (this.state === 'SUCCESSFULLY_COMPLETED') {
					return 'Visit Successful';
				} else if (this.state === 'ALREADY_COMPLETED') {
					return 'Activity already completed';
				} else if (this.state === 'NOT_PERMITTED') {
					return `Scan is not permitted`;
				}
				return '';
			},
		},
		beforeRouteEnter(to, from, next) {
			if (to.params.code) {
				$http.get(`/gamification-link/${to.params.code}`).then(data => {
					next(vm => vm.setData(data.data));
				});
			} else {
				next('/');
			}
		},
		beforeRouteUpdate(to, from, next) {
			if (to.params.code) {
				$http.get(`/gamification-link/${to.params.code}`).then(data => {
					this.setData(data.data);
					next();
				});
			} else {
				next('/');
			}
		},
		methods: {
			setData(data) {
				this.state = data.state;
				this.categoryTitle = data.category_title;
				this.activityTitle = data.activity_title;
				this.message = data.message;
				meta.setTitle(this.activityTitle);
			},
		},
	};
</script>
